<template>
  <base-section
    id="depositions"
    class="text-center pa-4"
    space="56"
  >
    <v-container>
      <base-icon class="mb-8">
        mdi-chat-processing-outline
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Conheça o relato de alguns dos pacientes"
      />
      <v-row justify="space-around">
        <v-col
          v-for="(deposition, i) in depositions"
          :key="i"
          cols="12"
          md="6"
        >
          <base-card
            :title="deposition.author"
            :subtitle="deposition.title"
            :src="deposition.image"
            :href="deposition.url"
            class="text-justify pa-1"
          >
            {{ deposition.text }}
          </base-card>
        </v-col>
      </v-row>
    </v-container>

    <base-info-card title="Relatos em avaliações semanais">
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >
        <v-slide-group
          v-model="print"
          class="pa-2"
          center-active
          :show-arrows="$vuetify.breakpoint.desktop"
        >
          <v-slide-item
            v-for="n in 13"
            :key="n"
            v-slot:default="{ active, toggle }"
          >
            <v-card
              class="ma-2"
              :img="require(`@/assets/patients/prints/${n}.jpg`)"
              height="533"
              width="300"
              @click="toggle"
            />
          </v-slide-item>
        </v-slide-group>
      </v-lazy>
    </base-info-card>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDepositions',

    data () {
      return {
        isActive: false,
        print: null,
        depositions: [
          {
            author: 'Robercharlly Nunes de Araújo',
            url: 'https://www.instagram.com/robecharlly/',
            title: 'Icó/CE',
            text: 'Fiz a consultoria com o Cassiano porque desde quando me interessei pelo ramo da nutrição sempre acompanhei suas redes sociais. Desde o início da nossa caminhada com o #teamdifisseo me identifiquei bastante com ele, pois além de ser um nutricionista extremamente inteligente é um amigo no qual pude compartilhar muitos momentos de minha vida. Foi um amigo de extrema importância no período mais forte da minha depressão. Me escutou, conversou, tinha os melhores conselhos, muitas vezes costumava dizer que ele era meu psicólogo. Por meio dessa mensagem deixo meu agradecimento esse período que pôde me acompanhar e agradeço também por sua amizade. Tmj, irmão👊🏻',
            image: require('@/assets/patients/robercharlly.jpg'),
          },
          {
            author: 'Ruan Célio dos Santos Sales',
            url: 'https://www.instagram.com/dr.ruansales/',
            title: 'Vitória/ES',
            text: 'Conheci o trabalho do Cassiano através de um fórum de musculação. Estava um pouco perdido quanto ao que deveria fazer na época e por ver a sensatez e conhecimento mostrados em suas participações, decidi o contratar. Desde então ele não tem sido para mim apenas um nutricionista, mas um irmão. Me ajudou a evoluir fisicamente e também em conhecimento, uma vez que dialogávamos a respeito das condutas a serem tomadas. Me incentivou a estudar mais e seguir na área de medicina esportiva. Digo com toda certeza que o Cassiano não mudou apenas o meu físico, mas toda a história da minha vida.',
            image: require('@/assets/patients/ruan.jpg'),
          },
          {
            author: 'Diego Ferrazzo',
            url: 'https://www.instagram.com/professordiegoferrazzo/',
            title: 'Riqueza/SC',
            text: 'No dia em que decidi fazer contato pela primeira vez e contratar a consultoria foi pelo fato de ter muitas informações a respeito de nutrição e não conseguir organiza-las e classificar sozinho o que realmente era bom pra mim. A consultoria do Cassiano me trouxe a prática que para ter resultados precisamos ter saúde e que saúde não é apenas a ausência de doenças e sim um pilar composto de saúde física, psíquica e social, caso uma dessas vertentes estiver em desequilíbrio poderemos compensar em algo (comida na maior parte das vezes). Estou fazendo acompanhamento a mais de um ano e o principal diferencial além do conhecimento técnico do Cassi, é que ele trata o cliente como amigo. Ter uma relação de afeto e empatia com você fideliza e te deixa suspeito em falar por isso constatei que o investimento é muito válido por ter vários pontos além do resultados físicos que pude relatar neste depoimento.',
            image: require('@/assets/patients/diego.jpg'),
          },
          {
            author: 'Jorge Karam',
            url: 'https://www.instagram.com/jorgeluiskaram/',
            title: 'Manaus/AM',
            text: 'O acompanhamento com o Cassiano foi muito importante para mim. Consegui obter resultados estéticos com um dieta extremamente tranquila e acessível. Ainda sim, o diferencial do Cass está na atenção que ele me deu durante o processo, tornando tudo mais fácil e me ajudando a não desistir. Ter esse apoio psicológico é/foi fundamental para a melhora da minha autoestima e autoaceitação. Além do fato de o Cassiano ser um profissional no qual me espelho, pois sou estudante de nutrição, o que acaba somando para a minha formação como profissional da área.',
            image: require('@/assets/patients/jorge.jpg'),
          },
          {
            author: 'Ana Sassanovicz',
            url: 'https://www.instagram.com/anapaulasassanovicz/',
            title: 'Chapecó/SC',
            text: 'Hoje estou aqui pensando a exatamente 1 ano atrás ao acaso encontrei no instagram um profissional chamado Cassiano Diniz que transformou minha vida interna e externamente; No primeiro contato, conversa, me pediu que apenas confiasse nele, sabe por quê? Porque cheguei no consultório desanimada, me sentindo incapaz, frustrada, sem auto estima, cheia de traumas e com medo de comer (sim eu tinha medo de comer uma simples banana). O trabalho do Cassiano vai muito além de passar protocolo de alimentação, trabalha a mente o interno, fez que eu voltasse a me enxergar como mulher novamente, elevando minha auto estima, perdendo o medo de comer e principalmente a não me cobrar e nem me punir por não seguir fielmente o cardápio, pois devemos compreender que temos uma vida, família, amigos, vontades e desejos além de um protocolo de alimentação pra seguir. E está tudo bem não conseguir seguir sempre 100%. Eu mudei meus hábitos, minha alimentação, meus pensamentos. Hoje posso dizer com toda certeza que minha mudança interna é muito maior do que a externa que todos estão vendo; estou me sentindo feliz, realizada, tranquila em paz comigo e com meu corpo. Então digo para você que está iniciando hoje processo para emagrecer, primeiro não tenham pressa; segundo esqueçam o peso da balança  terceiro se cerquem dos profissionais certos e que lutem contigo pelos teus objetivos e mais não desistam porque vocês também podem conseguir chegar lá como eu cheguei!',
            image: require('@/assets/patients/ana.jpg'),
          },
        ],
      }
    },
  }
</script>
